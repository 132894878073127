#isi {
  scroll-margin-top: 95px;
  width: 100%;
}
#isi #safety-anchor {
  scroll-margin-top: 95px;
}
#safety-spacer.close {
  min-height: 150vh;
}
#safety-wrapper {
  font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
  z-index: 999;
  position: fixed;
  right: 0;
  left: 0;
  bottom: calc(-100% + 135px);
  background: hsla(0,0%,100%,.85);
  max-height: 100vh;
  overflow-y: hidden;
  color: #002f55;
  box-shadow: 0 10px 13px 7px #747474,5px 5px 15px 5px transparent;
}
#safety-wrapper.open {
  overflow-y: auto;
  bottom: 0;
  position: static;
  max-height: none;
  -webkit-box-shadow: initial;
  box-shadow: none;
  padding-top: 10px;
}
#safety-wrapper.open #toggle-tab {
  display: none;
}
#isi .uppercase {
  text-transform: uppercase;
}
#safety-tab {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  padding: 15px 0 0;
  margin-bottom: 20px;
  cursor: pointer;
}
#safety-tab .flex {
  display: flex;
}
#safety-tab .items-center {
  align-items: center;
}
#safety-tab .gap-2 {
  gap: 0.5rem;
}
#safety-tab .gap-4 {
  gap: 1rem;
}
#safety-wrapper .isi-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 958px;
  padding-right: 40px;
  padding-left: 40px;
  text-align: left;
}
#safety-wrapper p, #safety-wrapper ul {
  color: inherit;
  text-align: left;
  font-size: 18px;
  padding: 0;
  margin: 18px 0;
}
#safety-wrapper .mb-0 {
  margin-bottom: 0;
}
#safety-wrapper .mt-0 {
  margin-top: 0;
}
#safety-wrapper .pl-4 {
  padding-left: 16px;
}
#safety-wrapper .font-18px {
  font-size: 18px;
}
#safety-wrapper a {
  color: #002f55;
}
#safety-wrapper .isi-table {
  border: 5px solid #002f55;
  padding: 8px 2px 0px 2px;
  margin-bottom: 1rem;
}
#safety-wrapper .isi-container ul li {
  color: #002f55;
  font-size: 18px;
  cursor: initial;
}

/* Mobile */
@media screen and (max-width: 767px) {
  #isi #safety-tab, #isi #safety-tab-right {
    flex-direction: column;
    align-items: start;
    margin-bottom: 0.25rem;
    padding-top: 0.5rem;
  }
  #isi #safety-tab-right {
    padding-top: 0.25rem;
    gap: 0.25rem;
  }
  #isi .isi-container .isi-table {
    padding-top: 0;
  }
  #safety-spacer.close {
    min-height: 100dvh;
  }
  #safety-wrapper {
    max-height: 100dvh;
  }

}
@media screen and (max-width: 560px) {
  #safety-wrapper .isi-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}