.main-wrapper {
    margin-bottom: 2rem;
}
.content {
    max-width: 958px;
    width: 100%;
    /* min-height: 768px; */
    padding: 40px;
    position: relative;
    /* background-color: rgba(255, 255, 255, .1); */
}

.text1 {
    width: 100%;
}

.calculate-wrap {
    width: 100%;
    max-width: 1200px;
    margin-top: 20px;
}
.calculate {
    width: 100%;
    padding-right: 320px;
    display: flex;
    position: relative;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 15px;
    border-radius: 4px;
    /* -webkit-box-shadow: 3px 1px 6px 1px rgba(0,0,0,.4);
    -moz-box-shadow: 3px 1x 6px 1px rgba(0,0,0,.4);
    box-shadow: 3px 1px 6px 1px rgba(0,0,0,.4); */
}
.weight {
    background: rgb(148,58,240);
    background: linear-gradient(90deg, rgba(148,58,240,1) 0%, rgba(74,53,163,1) 40%, rgba(0,47,85,1) 100%);
}
.volume {
    background: rgb(247,202,33);
    background: linear-gradient(90deg, rgba(247,202,33,1) 0%, rgba(251,152,23,1) 40%, rgba(255,108,14,1) 100%);
}
.quantity {
    background: rgb(255,108,14);
    background: linear-gradient(90deg, rgba(255,108,14,1) 0%, rgba(209,87,113,1) 40%, rgba(148,58,244,1) 100%);
}

.calculate p {
    font-size: 30px;
    line-height: 1;
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
    margin: 0;
    margin: 10px;
}

.label-wrap {
    width: auto;
    display: flex;
    align-items: center;
}

.icon {
    width: 80px;
    margin: 10px;
}
.icon img {
    width: 80px;
}
.label {
    /* margin-left: 20px; */
}
.input {
    width: 250px;
    padding: 10px 20px;
    background-color: #FFFFFF;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    /* -webkit-box-shadow: 3px 1px 6px 1px rgba(0,0,0,.4);
    -moz-box-shadow: 3px 1x 6px 1px rgba(0,0,0,.4);
    box-shadow: 3px 1px 6px 1px rgba(0,0,0,.4); */
}
.input-wrap {
    width: 300px;
    height: 100%;
}
.input p {
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    color: #002f55;
    word-wrap: break-word;
}
.calculate-holder {
    position: relative;
    z-index: 998;
}
.weight-input {
    display: flex;
    justify-content: space-between;
}
.weight-type-select {
    width: 30px;
    height: 30px;
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 40 40' style='enable-background:new 0 0 40 40;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23943AF4;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='20,30.9 0,11.5 2.3,9.1 20,26.3 37.7,9.1 40,11.5 '/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
}

.select-rotate {
    -webkit-animation: spin-forward .5s forwards;
    -moz-animation: spin-forward .5s forwards;
    animation: spin-forward .5s forwards;
}
.select-unrotate {
    -webkit-animation: spin-back .5s forwards;
    -moz-animation: spin-back .5s forwards;
    animation: spin-back .5s forwards;
}

input[type=text] {
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    color: #002f55;
    font-size: 32px !important;
    width: 110px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #000000;
    border-radius: 0;
    font-size: 16px;
    background-color: white;
    padding: 10px 20px 10px 0px;
}
  
input[type=text]:focus {
    outline: none;
}

/* rotate */
@-moz-keyframes spin-forward { 
    from {
        -moz-transform: rotate(0deg);
      }
      to {
        -moz-transform: rotate(-90deg);
      }
}
@-webkit-keyframes spin-forward { 
    from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(-90deg);
      }
}
@keyframes spin-forward { 
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }
}
/* un-rotate */
@-moz-keyframes spin-back { 
    from {
        -moz-transform: rotate(-90deg);
      }
      to {
        -moz-transform: rotate(0deg);
      }
}
@-webkit-keyframes spin-back { 
    from {
        -webkit-transform: rotate(-90deg);
      }
      to {
        -webkit-transform: rotate(0deg);
      }
}
@keyframes spin-back { 
    from {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }
      to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
}





.weight-type {
    /* width: 200px; */
    height: 60px;
    display: flex;
    background-color: #FFFFFF;
    position: absolute;
    right: 5px;
    bottom: -60px;
    padding: 14px 10px 10px 10px;
    z-index: 10;
    border-left: 1px solid #002f55;
    border-bottom: 1px solid #002f55;
    border-right: 1px solid #002f55;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    /* offset-x | offset-y | blur-radius | spread-radius | color */
    /* -webkit-box-shadow: 3px 1px 3px 1px rgba(0,0,0,.3);
    -moz-box-shadow: 3px 1x 3px 1px rgba(0,0,0,.3);
    box-shadow: 3px 1px 3px 1px rgba(0,0,0,.3); */

    -webkit-box-shadow: 1px 3px 5px 3px rgba(0,0,0,.3), inset 0px 11px 15px -10px #000000; 
    box-shadow: 1px 3px 5px 3px rgba(0,0,0,.3), inset 0px 11px 15px -10px #000000;
}
.display-weight-type {
    display: flex;
}
.unit {
    width: 70px;
    margin: 0px 5px;
    cursor: pointer;
}
.unit p {
    padding: 10px 15px;
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    text-align: center;
    line-height: 1; 
    color: #002f55;
    background-color: rgba(0, 47, 85, .3);
    margin: 0;
    border-radius: 3px;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.unit p:hover {
    background-color: #002f55;
    color: #ffffff;
}
.unit p.selected { 
    color: #ffffff;
    background-color: #002f55;
}



.fader {
    -moz-transition: opacity 0.7s linear;
    -o-transition: opacity 0.7s linear;
    -webkit-transition: opacity 0.7s linear;
    transition: opacity 0.7s linear;
  }
  
.fadedOut {
opacity: 0;
}

.text2 {
    width: 100%;
    margin-top: 20px;
}
.text2 h3 {
    font-size: 22px;
}

.text2 .footnote {
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.2;
    color: #ffffff;
    margin: 0;
}
.text2 .footnote.pb-4 {
    padding-bottom: 1rem;
}
.text2 .foot-link {
    text-decoration: underline;
    cursor: pointer;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.text2 .foot-link:hover {
    color: #f7c921;
}

.link-wrap {
    width: 100%;
    display: flex;
    margin: 10px 0px 0px;
}
.link-wrap .links {
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 1.2;
    color: #ffffff; 
    text-decoration: underline;
    margin-right: 20px;
    cursor: pointer;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.link-wrap .links:hover {
    color: #f7c921; 
}
.link-wrap .links:first-child {
    margin-right: 40px;
}

.debug {
    width: 480px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: #FFFFFF;
    padding: 20px;
    display: none;
}
.debug p {
    color: #000000;
}





@media only screen and (max-width: 1024px) {

    .calculate p {
        font-size: 30px;
        margin: 15px 15px 15px 0px
    }
    /* .link-wrap .links {
        font-size: 32px;
    }
    .text2 .footnote {
        font-size: 16px;
    } */


}

@media only screen and (max-width: 768px) {

    .content {
        padding: 40px;
    }

    .input {
        width: 100%;
        position: relative;
    }
    .calculate {
        display: block;
        padding-right: 0;
    }
    .weight-input {
        justify-content: center;
    }
    .weight-type-select {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }


}


@media only screen and (max-width: 560px) {

    .content {
        padding: 20px;
    }
    
    .calculate p {
      font-size: 28px;
    }

    .link-wrap .links {
        font-size: 28px;
    }
  
  }