header {
    width: 100%;
    height: 80px;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    padding: 15px 20px 0px 20px;
    /* background-color: #002F55; */
    z-index: 1000;
    transition: background 0.5s ease;
}
.header-wrap {
    width: 100%;
    /* max-width: 1920px; */
    display: flex;
    justify-content: space-between;
    /* background-color: rgba(255, 255, 255, .8); */
}
.header-wrap .logo {
    padding-bottom: 10px;
}
.solid-head {
    /* background-color: #002F55; */
    background-color: rgba(0, 47, 85, .8);
}
@media only screen and (max-width: 1200px) {

    header {
        height: 95px;
    }

}
@media only screen and (max-width: 460px) {

    .home-btn {
        font-size: 16px;
        padding: 10px 20px;
        margin-right: 0px;
    }
    .logo {
        height: 40px;
    }

}